@import "../Autobopr/Assets/functions.less";

@xxs: ~"(max-width: 450px)";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@desktop: ~"(min-width: 1330px)";
@mobileNavColor: #E3000F;
@castleDetailsColor: #8CBC11;
@CloudUrl: "https://bouncycastlenetwork-res.cloudinary.com/image/upload/f_auto/Sites/glendarragh-garden-services/";
@green: #8CBC11;
@blue: #304895;
@red: #E3000F;
/*#region fonts*/
.Sora, .BodyFont {
	font-family: "Sora", sans-serif;
	font-weight: 400;
}
// converts from px to rem & scales down for xxs screens
.fontSize(@fontSizePx,@scaleXxs: true,@xxsScale: 0.7, @rootFontSizePx: 16,@xxs: ~"(max-width: 450px)" ) {
	font-size: unit(round(@fontSizePx/@rootFontSizePx,3),rem);

	& when(@scaleXxs = true) {
		@media @xxs {
			font-size: unit(round((@fontSizePx * @xxsScale)/@rootFontSizePx,3),rem);
		}
	}
}

html {
	font-size: 16px;
}

body {
	.BodyFont;
}

.nicefont {
}

.theme-btn {
	font-weight: 600;
	display: inline-block;
	.fontSize(18,false);
	transition: background 0.3s, color 0.3s;
	padding: 10px 15px;
	text-transform: capitalize;

	&--white {
		color: #8CBC12;
		background: #fff;
		border: 1px solid #fff;

		&:hover {
			color: #fff;
			background: #8CBC12;
		}
	}

	&--green {
		background: #8CBC12;
		color: #fff;
		border: 1px solid #8CBC12;

		&:hover {
			color: #8CBC12;
			background: #fff;
		}
	}
}

.main-content {
	padding: 50px 0;
	width: ~'calc(100%)';

	.home-gallery {
		display: none;
	}
}

.pageContent.textContent {
	.fontSize(16,false);

	@media @xxs {
		a {
			// for long links in chrome
			word-break: break-word;
		}
	}

	@media @mobile {
		text-align: center;
	}

	h1, h2 {
		margin-bottom: 30px;
		.fontSize(48);
	}

	h1 {
		margin-top: 0;
	}

	p {
		margin-bottom: 20px;
	}
}

#BodyContent.pageContent.textContent {
	padding-top: 0;
	@linkColor: #ec068d;

	a {
		position: relative;
		padding: 0 3px;
		margin: 0 1px;
		display: inline-block;
		color: @linkColor;
		transition: color 0.3s;
		z-index:1;

		&:hover,&:focus {
			color: #fff;

			&:before {
				transform: scaleY(1);
			}
		}

		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: @linkColor;
			transition: transform 0.3s;
			z-index: -1;
			transform-origin: center bottom;
			transform: scaleY(0.1);
		}
	}
}

h2, h3, h4 {
}
/*#endregion fonts*/
form {
	position: relative;
	max-width: 100vw;
}

.outer-wrapper {
	max-width: 100vw;
	overflow-x: hidden;
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	// any non-homepage page
	&--alt {
		.main-content {
			flex-grow: 1;
		}
	}
	// homepage only
	&--home {
		.main-content {
			background: #304895;

			&__main {
				background: #fff;
				border: 3px solid #8CBC12;
				padding: 20px;

				@media @sm {
					padding-top: 50px;
				}
			}

			&__grid {
				margin-top: -120px;
				display: grid;
				gap: 20px 20px;
				grid-template-columns: 1fr 1fr;
				grid-template-rows: auto auto auto auto auto;
				grid-template-areas: 'main-content__main main-content__main' 'home-gallery-01 home-gallery-02' 'home-gallery-01 home-gallery-04' 'home-gallery-03 home-gallery-03' 'home-gallery-05 home-gallery-06' 'home-gallery-07 home-gallery-08';

				@media @md {
					grid-template-columns: 1fr 1fr 1fr 1fr;
					grid-template-rows: auto auto auto;
					grid-template-areas: 'home-gallery-01 home-gallery-02 home-gallery-03 home-gallery-03' 'home-gallery-01 main-content__main main-content__main home-gallery-04' 'home-gallery-05 home-gallery-06 home-gallery-07 home-gallery-08';
				}
			}

			&__main {
				grid-area: main-content__main;
			}

			&__gallery-image {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			.home-gallery {
				background: #fff;
				position: relative;
				display: block;

				&:before {
					content: "";
					display: block;
				}

				&-01 {
					grid-area: home-gallery-01;

					&:before {
						padding-top: 207.5%
					}
				}

				&-02 {
					grid-area: home-gallery-02;

					&:before {
						padding-top: 100%
					}
				}

				&-03 {
					grid-area: home-gallery-03;

					&:before {
						padding-top: 47.096%
					}
				}

				&-04 {
					grid-area: home-gallery-04;

					&:before {
						padding-top: 100%
					}
				}

				&-05 {
					grid-area: home-gallery-05;

					&:before {
						padding-top: 100%
					}
				}

				&-06 {
					grid-area: home-gallery-06;

					&:before {
						padding-top: 100%
					}
				}

				&-07 {
					grid-area: home-gallery-07;

					&:before {
						padding-top: 100%
					}
				}

				&-08 {
					grid-area: home-gallery-08;

					&:before {
						padding-top: 100%
					}
				}
			}
		}
	}
	// category page only
	&--category {
	}
	// contact page only
	&--contact {
	}

	&--product {
		.main-content {
			padding-bottom: 30px;
		}
	}
}

.container {
	position: relative;
	width: 100%;

	@media @mobile {
	}

	@media @tablet {
	}

	@media @desktop {
		width: 1300px;
	}
}
/*#region topLinksMobile*/
#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0 0;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
/*#endregion topLinksMobile*/
.header {
	padding: 20px 0;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	@media @mobile {
		padding-top: 80px;
	}

	@media @sm {
		flex-direction: row;
	}

	&__wrapper {
		position: relative;
	}

	&__logo-col {
		flex-grow: 1;
		width: 100%;
		text-align: center;
		margin-bottom: 40px;

		@media @md {
			margin-bottom: 0;
			width: auto;
			text-align: left;
		}
	}

	&__logo {
		display: inline-block;
		position: relative;
		//adjust max width to suit
		max-width: 444px;
		width: 100%;

		/*@media @lg {
			max-width: 160px;
		}*/

		&:before {
			content: "";
			display: block;
			//adjust padding-top to suit
			padding-top: 29.7297%;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	&__info-col {
		width: 100%;

		@media @md {
			width: auto;
		}
	}

	&__social-links {
		display: flex;
		justify-content: center;
	}

	&__social-link {
		margin: 0 5px;
	}

	&__phone-row {
		display: flex;
		justify-content: center;

		@media @mobile {
			flex-direction: column;
			align-items: center;
		}

		@media @md {
			justify-content: flex-end;
		}
	}

	&__phone-group {
		display: flex;
		align-items: center;

		@media @sm {
			margin-left: 20px;
			margin-right: 20px;
		}

		@media @md {
			margin-left: 0;
			margin-right: 0;
		}

		&:first-child {
			@media @mobile {
				margin-bottom: 20px;
			}

			@media @md {
				margin-right: 40px;
			}
		}
	}

	&__phone-icon {
		width: 35px;
		height: 35px;
		margin-right: 20px;
	}

	&__phone-group-title {
		color: #fff;
		text-transform: uppercase;
		.Sora;
		.fontSize(18,false);
	}

	&__phone {
		color: #87B716;
		.fontSize(24,false);

		&:hover {
			color: #87B716;
		}
	}
}

.toplinks {
	position: relative;
	margin-top: 25px;

	&__wrapper {
	}

	&__container {
		padding-left: 0;
		padding-right: 0;
	}

	&__ul {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 0;
	}

	&__li {
		position: relative;
		list-style: none;
		text-align: center;

		.dropdown-toggle:focus {
			text-decoration: none;
		}

		&:last-child .toplinks__link {
			background: #8CBC12;
		}
	}

	&__link {
		position: relative;
		display: block;
		padding: 15px 25px;
		color: #fff;
		transition: background 0.3s;
		.fontSize(22);

		&:hover, &.active, &:focus {
			color: #fff;
		}

		&:hover {
			background: #8CBC12;
		}
	}

	&__dropdown {
		padding: 10px;
		margin: 0;
		border: none;
		box-shadow: none;
		text-align: center;
		background-color: #8CBC12 !important;
		box-shadow: 0 0 3px rgba(0,0,0,0.25);
		border-radius: 0;
	}

	&__dropdown-li {
		display: block;
	}

	&__dropdown-link {
		position: relative;
		padding: 5px 10px !important;
		transition: color 0.3s;
		color: #fff !important;
		.fontSize(22);

		&:hover, &:focus, &.active {
			background-color: inherit !important;
			color: #ffe400 !important;
		}
	}
}

.banner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	&:after {
		.StickToEdges;
		background-color: rgba(0,0,0,0.5);
	}

	&__message {
		text-align: center;
		margin: 0 auto 220px;
		color: #fff;
		.fontSize(22,false);
		max-width: 816px;

		h2 {
			.fontSize(54);
		}
	}

	#CarouselContainer, .carousel-inner, .item {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.item {
		a, img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.carousel-indicators {
		display: none;
	}

	&__link {
		margin: 0 8px;

		@media @mobile {
			margin-bottom: 10px;
		}
	}

	&__links {
		margin-top: 20px;
	}
}

.featured-categories {
	padding: 60px 0 130px;
	position: relative;

	&__inner {
		display: flex;
		justify-content: space-around;
		position: relative;
		flex-direction: column;

		@media @sm {
			flex-direction: row;
		}
	}

	&__link {
		background: @green;
		text-align: center;
		padding: 20px;
		display: flex;
		flex-direction: column;
		position: relative;
		width: 100%;
		margin: 20px 0;

		@media @sm {
			width: 45%;
			margin-top: -120px;
		}

		&:hover, &:focus {
			.featured-categories__image {
				transform: scale(1.05);
			}
		}
	}
	// this makes a box that will keep its aspect ratio as the box size decreases
	&__image-panel {
		position: relative;
		overflow: hidden;
		max-width: 162px;
		border-radius: 50%;
		margin: auto;

		@media @md {
			margin-top: -80px;
		}
	}
	// then we can set the image into the box so that it'll never be any bigger than the box
	&__image {
		width: 100%;
		height: 100%;
		object-fit: contain;
		max-width: 162px;
		transition: transform 0.3s;
	}

	&__title {
		color: #FFF;
		font-weight: 600;
		.fontSize(44);
		position: relative;
	}

	&__description {
		.fontSize(18);
		color: #FFF;
		position: relative;
	}

	&__button {
		width: fit-content;
		margin: 10px auto;
	}
}

//.PageListings is on search results page
.page-listings,.PageListings {
	& > div {
		@media (max-width:420px) {
			width: 100%;
		}
	}
}

.castlePanel {
	width: 100%;
	margin: 0 0 30px;
	display: flex;
	flex-direction: column;
	border: none;
	overflow: hidden;
	padding: 10px 10px 20px;
	border-radius: 10px;
	box-shadow: 0 3px 5px rgba(0,0,0,0.3);
	transition: box-shadow 0.3s;

	&:hover {
		box-shadow: 0 2px 2px rgba(0,0,0,0.1);
	}

	.SingleImagePanel {
		height: auto;
		background-color: transparent;
		padding-top: 0;

		&:before {
			content: "";
			display: block;
			padding-top: 100%;
			width: 100%;
		}
	}

	.castleTitlePanel {
		padding: 0 5px;
		display: flex;
		margin-top: 10px;
		justify-content: center;
		align-items: center;

		a {
			.fontSize(20,false);
			.Sora;
		}
	}

	.shortProductDescription {
	}

	.castlePriceDefault, .castlePriceDiscounted {
		padding: 0 5px;
		margin-top: 10px;
		.Sora;
		.fontSize(16,false);

		span.oldprice {
			font-size: 0.8rem;
		}

		span.newprice {
			font-size: 1rem;
		}
	}

	span.oldprice, span.newprice {
	}

	.castleCheckBook {
		height: auto;
		order: 4;
		padding: 0 5px;
		margin-top: 10px;
		text-transform: uppercase;
		.fontSize(16,false);
	}
}

.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
	.fontSize(32);
}

.DetailsTitle h1, .DetailsTitle h2 {
	padding: 10px;
}

.product [itemprop="description"] {
	font-size: 16px;
}



a.BackToCategory {
	@bgColor: #fa0307;
	text-shadow: none;
	border-radius: 4px;
	background-color: @bgColor;

	@media @mobile {
		transform: translateY(30px);
		margin-bottom: 0;
	}

	&:hover {
		text-shadow: none;
		background-color: @bgColor;
	}
}

.DetailsLeft {
	background-image: none;
	padding-bottom: 0;
}

.BookButtons {
	text-align: center;

	.BookButton, .CheckButton {
		@media @sm {
			width: auto;
			margin-left: auto;
			float: none;
			margin: 0 10px 10px;
		}
	}
}

.services {
	padding: 60px 0;
	text-align: center;

	&__text {
		margin: 30px 0;
		color: #4b4b4b;
		.fontSize(18);

		h2 {
			color: @blue;
			.fontSize(44);
		}
	}

	&__inner {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	&__panel {
		width: 48%;
		background: #eaeaea;
		padding: 40px 20px;
		margin: 10px auto;

		@media @md {
			width: 23%;
		}
	}

	&__title {
		color: @green;
		margin: 10px 0;
		.fontSize(28);

		@media @lg {
			.fontSize(34);
		}
	}
}

.testimonials {
	padding: 60px 0;
	background: @green;
	text-align: center;

	&__text {
		color: #FFF;
		.fontSize(18);
		margin: 20px 0;
		h2 {
			.fontSize(44);
		}
	}

	.RotatorTestimonial {
		background: #FFF;
		padding: 20px;
		color: #4b4b4b;
		text-align: center;
		margin: 20px 10px;
	}

	.RotatorTestimonialContent {
		display: flex;
		flex-direction: column-reverse;
		text-align: center;
	}

	&__inner {
		position: relative;
	}

	time {
		display: none;
	}

	a[itemprop="author"] {
		color: @green;
		.fontSize(24);
		font-weight: 600;
	}

	p[itemprop="reviewBody"] {
	}

	&__stars {
	}
}

.footer {
	background: #FFF;
	color: #4B4B4B;
	.fontSize(18);

	a, p {
		color: #4B4B4B;

		&:hover {
			color: #4B4B4B;
		}
	}

	h3 {
		color: #336699;
		.fontSize(22);
		font-weight: 600;
	}

	&__text-left-md {
		@media @md {
			text-align: left;
		}
	}

	&__text-right-md {
		@media @md {
			text-align: right;
		}
	}

	&__top {
		&-col {
			text-align: center;
			margin: 10px 0;
			@media @sm {
				text-align: left;
				width: 48%;
				max-width: 50%;
			}
			@media @md {
				max-width: 25%;
			}
		}
	}

	&__top-inner {
		display: flex;
		flex-direction: column;
		border-bottom: 1px solid #959595;
		padding: 30px 0;
		position: relative;
		flex-wrap: wrap;

		@media @sm {
			flex-direction: row;
			justify-content: space-evenly;
		}

		@media @lg {
			justify-content: space-between;
		}
	}

	&__bottom {
		padding: 30px 0;
		text-align: center;
	}

	&__bottom-inner {
	}

	.newsletter {
		position: relative;

		&__email {
			height: 55px;
			border: 1px solid #494949;
			width: 100%;
			padding-left: 5%;
			color: #6D6D6D;
			margin: 10px 0;
		}
		&__button {
			content: url("@{CloudUrl}icon-email.png");
			width: 25px;
			height: 25px;
			position: absolute;
			right: 5%;
			top: 30%;
		}
	}

	&__links {
		text-align: center;
		@media @sm {
			text-align: left;
		}
	}

	&__link {
		margin: 0 5px;
		display: block;
	}

	&__social-links {
		display: flex;
		justify-content: center;
		@media @md {
			justify-content: left;
		}
	}

	&__social-link {
		margin: 0 5px;
	}

	#BCNLink {
		display: inline-block;
		margin-top: 15px;

		@media @md {
			margin-top: 0;
		}
	}
}