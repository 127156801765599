/*#region fonts*/
.Sora,
.BodyFont {
  font-family: "Sora", sans-serif;
  font-weight: 400;
}
html {
  font-size: 16px;
}
body {
  font-family: "Sora", sans-serif;
  font-weight: 400;
}
.theme-btn {
  font-weight: 600;
  display: inline-block;
  font-size: 1.125rem;
  -webkit-transition: background 0.3s, color 0.3s;
  transition: background 0.3s, color 0.3s;
  padding: 10px 15px;
  text-transform: capitalize;
}
.theme-btn--white {
  color: #8CBC12;
  background: #fff;
  border: 1px solid #fff;
}
.theme-btn--white:hover {
  color: #fff;
  background: #8CBC12;
}
.theme-btn--green {
  background: #8CBC12;
  color: #fff;
  border: 1px solid #8CBC12;
}
.theme-btn--green:hover {
  color: #8CBC12;
  background: #fff;
}
.main-content {
  padding: 50px 0;
  width: calc(100%);
}
.main-content .home-gallery {
  display: none;
}
.pageContent.textContent {
  font-size: 1rem;
}
@media (max-width: 450px) {
  .pageContent.textContent a {
    word-break: break-word;
  }
}
@media (max-width: 767px) {
  .pageContent.textContent {
    text-align: center;
  }
}
.pageContent.textContent h1,
.pageContent.textContent h2 {
  margin-bottom: 30px;
  font-size: 3rem;
}
@media (max-width: 450px) {
  .pageContent.textContent h1,
  .pageContent.textContent h2 {
    font-size: 2.1rem;
  }
}
.pageContent.textContent h1 {
  margin-top: 0;
}
.pageContent.textContent p {
  margin-bottom: 20px;
}
#BodyContent.pageContent.textContent {
  padding-top: 0;
}
#BodyContent.pageContent.textContent a {
  position: relative;
  padding: 0 3px;
  margin: 0 1px;
  display: inline-block;
  color: #ec068d;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  z-index: 1;
}
#BodyContent.pageContent.textContent a:hover,
#BodyContent.pageContent.textContent a:focus {
  color: #fff;
}
#BodyContent.pageContent.textContent a:hover:before,
#BodyContent.pageContent.textContent a:focus:before {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}
#BodyContent.pageContent.textContent a:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ec068d;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  z-index: -1;
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  -webkit-transform: scaleY(0.1);
          transform: scaleY(0.1);
}
/*#endregion fonts*/
form {
  position: relative;
  max-width: 100vw;
}
.outer-wrapper {
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.outer-wrapper--alt .main-content {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.outer-wrapper--home .main-content {
  background: #304895;
}
.outer-wrapper--home .main-content__main {
  background: #fff;
  border: 3px solid #8CBC12;
  padding: 20px;
}
@media (min-width: 768px) {
  .outer-wrapper--home .main-content__main {
    padding-top: 50px;
  }
}
.outer-wrapper--home .main-content__grid {
  margin-top: -120px;
  display: grid;
  gap: 20px 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto auto auto;
  grid-template-areas: 'main-content__main main-content__main' 'home-gallery-01 home-gallery-02' 'home-gallery-01 home-gallery-04' 'home-gallery-03 home-gallery-03' 'home-gallery-05 home-gallery-06' 'home-gallery-07 home-gallery-08';
}
@media (min-width: 992px) {
  .outer-wrapper--home .main-content__grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas: 'home-gallery-01 home-gallery-02 home-gallery-03 home-gallery-03' 'home-gallery-01 main-content__main main-content__main home-gallery-04' 'home-gallery-05 home-gallery-06 home-gallery-07 home-gallery-08';
  }
}
.outer-wrapper--home .main-content__main {
  grid-area: main-content__main;
}
.outer-wrapper--home .main-content__gallery-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.outer-wrapper--home .main-content .home-gallery {
  background: #fff;
  position: relative;
  display: block;
}
.outer-wrapper--home .main-content .home-gallery:before {
  content: "";
  display: block;
}
.outer-wrapper--home .main-content .home-gallery-01 {
  grid-area: home-gallery-01;
}
.outer-wrapper--home .main-content .home-gallery-01:before {
  padding-top: 207.5%;
}
.outer-wrapper--home .main-content .home-gallery-02 {
  grid-area: home-gallery-02;
}
.outer-wrapper--home .main-content .home-gallery-02:before {
  padding-top: 100%;
}
.outer-wrapper--home .main-content .home-gallery-03 {
  grid-area: home-gallery-03;
}
.outer-wrapper--home .main-content .home-gallery-03:before {
  padding-top: 47.096%;
}
.outer-wrapper--home .main-content .home-gallery-04 {
  grid-area: home-gallery-04;
}
.outer-wrapper--home .main-content .home-gallery-04:before {
  padding-top: 100%;
}
.outer-wrapper--home .main-content .home-gallery-05 {
  grid-area: home-gallery-05;
}
.outer-wrapper--home .main-content .home-gallery-05:before {
  padding-top: 100%;
}
.outer-wrapper--home .main-content .home-gallery-06 {
  grid-area: home-gallery-06;
}
.outer-wrapper--home .main-content .home-gallery-06:before {
  padding-top: 100%;
}
.outer-wrapper--home .main-content .home-gallery-07 {
  grid-area: home-gallery-07;
}
.outer-wrapper--home .main-content .home-gallery-07:before {
  padding-top: 100%;
}
.outer-wrapper--home .main-content .home-gallery-08 {
  grid-area: home-gallery-08;
}
.outer-wrapper--home .main-content .home-gallery-08:before {
  padding-top: 100%;
}
.outer-wrapper--product .main-content {
  padding-bottom: 30px;
}
.container {
  position: relative;
  width: 100%;
}
@media (min-width: 1330px) {
  .container {
    width: 1300px;
  }
}
/*#region topLinksMobile*/
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #E3000F;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: "Sora", sans-serif;
  font-weight: 400;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0 0;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
/*#endregion topLinksMobile*/
.header {
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 767px) {
  .header {
    padding-top: 80px;
  }
}
@media (min-width: 768px) {
  .header {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.header__wrapper {
  position: relative;
}
.header__logo-col {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .header__logo-col {
    margin-bottom: 0;
    width: auto;
    text-align: left;
  }
}
.header__logo {
  display: inline-block;
  position: relative;
  max-width: 444px;
  width: 100%;
  /*@media @lg {
			max-width: 160px;
		}*/
}
.header__logo:before {
  content: "";
  display: block;
  padding-top: 29.7297%;
}
.header__logo img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.header__info-col {
  width: 100%;
}
@media (min-width: 992px) {
  .header__info-col {
    width: auto;
  }
}
.header__social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.header__social-link {
  margin: 0 5px;
}
.header__phone-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 767px) {
  .header__phone-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media (min-width: 992px) {
  .header__phone-row {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}
.header__phone-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 768px) {
  .header__phone-group {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media (min-width: 992px) {
  .header__phone-group {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .header__phone-group:first-child {
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) {
  .header__phone-group:first-child {
    margin-right: 40px;
  }
}
.header__phone-icon {
  width: 35px;
  height: 35px;
  margin-right: 20px;
}
.header__phone-group-title {
  color: #fff;
  text-transform: uppercase;
  font-family: "Sora", sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
}
.header__phone {
  color: #87B716;
  font-size: 1.5rem;
}
.header__phone:hover {
  color: #87B716;
}
.toplinks {
  position: relative;
  margin-top: 25px;
}
.toplinks__container {
  padding-left: 0;
  padding-right: 0;
}
.toplinks__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}
.toplinks__li {
  position: relative;
  list-style: none;
  text-align: center;
}
.toplinks__li .dropdown-toggle:focus {
  text-decoration: none;
}
.toplinks__li:last-child .toplinks__link {
  background: #8CBC12;
}
.toplinks__link {
  position: relative;
  display: block;
  padding: 15px 25px;
  color: #fff;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  font-size: 1.375rem;
}
@media (max-width: 450px) {
  .toplinks__link {
    font-size: 0.962rem;
  }
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  color: #fff;
}
.toplinks__link:hover {
  background: #8CBC12;
}
.toplinks__dropdown {
  padding: 10px;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-align: center;
  background-color: #8CBC12 !important;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  border-radius: 0;
}
.toplinks__dropdown-li {
  display: block;
}
.toplinks__dropdown-link {
  position: relative;
  padding: 5px 10px !important;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  color: #fff !important;
  font-size: 1.375rem;
}
@media (max-width: 450px) {
  .toplinks__dropdown-link {
    font-size: 0.962rem;
  }
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus,
.toplinks__dropdown-link.active {
  background-color: inherit !important;
  color: #ffe400 !important;
}
.banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.5);
}
.banner__message {
  text-align: center;
  margin: 0 auto 220px;
  color: #fff;
  font-size: 1.375rem;
  max-width: 816px;
}
.banner__message h2 {
  font-size: 3.375rem;
}
@media (max-width: 450px) {
  .banner__message h2 {
    font-size: 2.362rem;
  }
}
.banner #CarouselContainer,
.banner .carousel-inner,
.banner .item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner .item a,
.banner .item img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.banner .carousel-indicators {
  display: none;
}
.banner__link {
  margin: 0 8px;
}
@media (max-width: 767px) {
  .banner__link {
    margin-bottom: 10px;
  }
}
.banner__links {
  margin-top: 20px;
}
.featured-categories {
  padding: 60px 0 130px;
  position: relative;
}
.featured-categories__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 768px) {
  .featured-categories__inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.featured-categories__link {
  background: #8CBC11;
  text-align: center;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  width: 100%;
  margin: 20px 0;
}
@media (min-width: 768px) {
  .featured-categories__link {
    width: 45%;
    margin-top: -120px;
  }
}
.featured-categories__link:hover .featured-categories__image,
.featured-categories__link:focus .featured-categories__image {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.featured-categories__image-panel {
  position: relative;
  overflow: hidden;
  max-width: 162px;
  border-radius: 50%;
  margin: auto;
}
@media (min-width: 992px) {
  .featured-categories__image-panel {
    margin-top: -80px;
  }
}
.featured-categories__image {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  max-width: 162px;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.featured-categories__title {
  color: #FFF;
  font-weight: 600;
  font-size: 2.75rem;
  position: relative;
}
@media (max-width: 450px) {
  .featured-categories__title {
    font-size: 1.925rem;
  }
}
.featured-categories__description {
  font-size: 1.125rem;
  color: #FFF;
  position: relative;
}
@media (max-width: 450px) {
  .featured-categories__description {
    font-size: 0.787rem;
  }
}
.featured-categories__button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 10px auto;
}
@media (max-width: 420px) {
  .page-listings > div,
  .PageListings > div {
    width: 100%;
  }
}
.castlePanel {
  width: 100%;
  margin: 0 0 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: none;
  overflow: hidden;
  padding: 10px 10px 20px;
  border-radius: 10px;
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
}
.castlePanel:hover {
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.castlePanel .SingleImagePanel {
  height: auto;
  background-color: transparent;
  padding-top: 0;
}
.castlePanel .SingleImagePanel:before {
  content: "";
  display: block;
  padding-top: 100%;
  width: 100%;
}
.castlePanel .castleTitlePanel {
  padding: 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.castlePanel .castleTitlePanel a {
  font-size: 1.25rem;
  font-family: "Sora", sans-serif;
  font-weight: 400;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  padding: 0 5px;
  margin-top: 10px;
  font-family: "Sora", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}
.castlePanel .castlePriceDefault span.oldprice,
.castlePanel .castlePriceDiscounted span.oldprice {
  font-size: 0.8rem;
}
.castlePanel .castlePriceDefault span.newprice,
.castlePanel .castlePriceDiscounted span.newprice {
  font-size: 1rem;
}
.castlePanel .castleCheckBook {
  height: auto;
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
  padding: 0 5px;
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 1rem;
}
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #8CBC11;
  font-size: 2rem;
}
@media (max-width: 450px) {
  .DetailsTitle h1,
  .DetailsTitle h2,
  .DetailsMainImage {
    font-size: 1.4rem;
  }
}
.DetailsTitle h1,
.DetailsTitle h2 {
  padding: 10px;
}
.product [itemprop="description"] {
  font-size: 16px;
}
a.BackToCategory {
  text-shadow: none;
  border-radius: 4px;
  background-color: #fa0307;
}
@media (max-width: 767px) {
  a.BackToCategory {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    margin-bottom: 0;
  }
}
a.BackToCategory:hover {
  text-shadow: none;
  background-color: #fa0307;
}
.DetailsLeft {
  background-image: none;
  padding-bottom: 0;
}
.BookButtons {
  text-align: center;
}
@media (min-width: 768px) {
  .BookButtons .BookButton,
  .BookButtons .CheckButton {
    width: auto;
    margin-left: auto;
    float: none;
    margin: 0 10px 10px;
  }
}
.services {
  padding: 60px 0;
  text-align: center;
}
.services__text {
  margin: 30px 0;
  color: #4b4b4b;
  font-size: 1.125rem;
}
@media (max-width: 450px) {
  .services__text {
    font-size: 0.787rem;
  }
}
.services__text h2 {
  color: #304895;
  font-size: 2.75rem;
}
@media (max-width: 450px) {
  .services__text h2 {
    font-size: 1.925rem;
  }
}
.services__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.services__panel {
  width: 48%;
  background: #eaeaea;
  padding: 40px 20px;
  margin: 10px auto;
}
@media (min-width: 992px) {
  .services__panel {
    width: 23%;
  }
}
.services__title {
  color: #8CBC11;
  margin: 10px 0;
  font-size: 1.75rem;
}
@media (max-width: 450px) {
  .services__title {
    font-size: 1.225rem;
  }
}
@media (min-width: 1200px) {
  .services__title {
    font-size: 2.125rem;
  }
}
@media (min-width: 1200px) and (max-width: 450px) {
  .services__title {
    font-size: 1.487rem;
  }
}
.testimonials {
  padding: 60px 0;
  background: #8CBC11;
  text-align: center;
}
.testimonials__text {
  color: #FFF;
  font-size: 1.125rem;
  margin: 20px 0;
}
@media (max-width: 450px) {
  .testimonials__text {
    font-size: 0.787rem;
  }
}
.testimonials__text h2 {
  font-size: 2.75rem;
}
@media (max-width: 450px) {
  .testimonials__text h2 {
    font-size: 1.925rem;
  }
}
.testimonials .RotatorTestimonial {
  background: #FFF;
  padding: 20px;
  color: #4b4b4b;
  text-align: center;
  margin: 20px 10px;
}
.testimonials .RotatorTestimonialContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  text-align: center;
}
.testimonials__inner {
  position: relative;
}
.testimonials time {
  display: none;
}
.testimonials a[itemprop="author"] {
  color: #8CBC11;
  font-size: 1.5rem;
  font-weight: 600;
}
@media (max-width: 450px) {
  .testimonials a[itemprop="author"] {
    font-size: 1.05rem;
  }
}
.footer {
  background: #FFF;
  color: #4B4B4B;
  font-size: 1.125rem;
}
@media (max-width: 450px) {
  .footer {
    font-size: 0.787rem;
  }
}
.footer a,
.footer p {
  color: #4B4B4B;
}
.footer a:hover,
.footer p:hover {
  color: #4B4B4B;
}
.footer h3 {
  color: #336699;
  font-size: 1.375rem;
  font-weight: 600;
}
@media (max-width: 450px) {
  .footer h3 {
    font-size: 0.962rem;
  }
}
@media (min-width: 992px) {
  .footer__text-left-md {
    text-align: left;
  }
}
@media (min-width: 992px) {
  .footer__text-right-md {
    text-align: right;
  }
}
.footer__top-col {
  text-align: center;
  margin: 10px 0;
}
@media (min-width: 768px) {
  .footer__top-col {
    text-align: left;
    width: 48%;
    max-width: 50%;
  }
}
@media (min-width: 992px) {
  .footer__top-col {
    max-width: 25%;
  }
}
.footer__top-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-bottom: 1px solid #959595;
  padding: 30px 0;
  position: relative;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (min-width: 768px) {
  .footer__top-inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }
}
@media (min-width: 1200px) {
  .footer__top-inner {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.footer__bottom {
  padding: 30px 0;
  text-align: center;
}
.footer .newsletter {
  position: relative;
}
.footer .newsletter__email {
  height: 55px;
  border: 1px solid #494949;
  width: 100%;
  padding-left: 5%;
  color: #6D6D6D;
  margin: 10px 0;
}
.footer .newsletter__button {
  content: url("https://bouncycastlenetwork-res.cloudinary.com/image/upload/f_auto/Sites/glendarragh-garden-services/icon-email.png");
  width: 25px;
  height: 25px;
  position: absolute;
  right: 5%;
  top: 30%;
}
.footer__links {
  text-align: center;
}
@media (min-width: 768px) {
  .footer__links {
    text-align: left;
  }
}
.footer__link {
  margin: 0 5px;
  display: block;
}
.footer__social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (min-width: 992px) {
  .footer__social-links {
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
  }
}
.footer__social-link {
  margin: 0 5px;
}
.footer #BCNLink {
  display: inline-block;
  margin-top: 15px;
}
@media (min-width: 992px) {
  .footer #BCNLink {
    margin-top: 0;
  }
}
